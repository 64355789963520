<template>
  <div class="btn-container">User Profile</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
</style>
